import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    padding: 5
  },
  header: {
    width: 280,
    margin: '70px auto',
    fontWeight: 700,
    fontSize: 20
  },
  thumbsContainer: {
    width: 180,
    margin: '0 auto',
    display: 'flex',
    color: 'white',
    fontSize: 25,
    '&.exit': {
      animation: '$scale-out 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both'
    },
    '& .label': {
      padding: '10px 0',
      fontSize: 15
    },
    '& .far': {
      padding: '10px 11px',
      margin: '0px 10px',
      borderRadius: '50%',
      '&:active': {
        transform: 'translateY(4px)'
      }
    },
    '& .fa-thumbs-up': {
      backgroundColor: '#27c327',
      borderBottomRightRadius: 0,
      transition: '0.2s ease-in-out',
      '&:hover': {
        transform: 'scale(1.1) rotate(-15deg)'
      }
    },
    '& .thumbs-up-label': {
      color: '#27c327',
    },
    '& .fa-thumbs-down': {
      backgroundColor: '#da0101',
      borderBottomLeftRadius: 0,
      transition: '0.2s ease-in-out',
      '&:hover': {
        transform: 'scale(1.1) rotate(15deg)'
      }
    },
    '& .thumbs-down-label': {
      color: '#da0101',
    },
  },
  thumbDiv: {
    cursor: 'pointer'
  },
  description: {
    marginTop: 30
  },
  '@keyframes scale-out': {
    '0%': {
      transform: 'scale(1)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(0)',
      opacity: 0
    }
  }
}));
